(function() {
    'use strict';

    angular.module('Logger', [])

    // Angular Constants
    // toastr is actually set in toastr.js as window.toastr
    // making globally available here.
    .constant('toastr', toastr)

    .config(toastrConfig);

    /* @ngInject */
    function toastrConfig(toastr) {
        toastr.options.timeOut = 8000;
        toastr.options.positionClass = 'toast-top-right';
    }

})();

(function() {
    'use strict';

    angular
        .module('Logger')
        .factory('logger', logger);

    logger.$inject = ['$log', 'toastr'];

    function logger($log, toastr) {
        var service = {

            // turn off the toasts programmatically.
            showToasts: true,

            error   : error,
            info    : info,
            success : success,
            warn    : warn,
            clear   : clear,
            remove  : remove

        };

        return service;
        /////////////////////

        function error(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.error(message, title, overrideOptions);
            }
            $log.error('Error: ' + message, data);
        }

        function info(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.info(message, title, overrideOptions);
            }
            $log.info('Info: ' + message, data);
        }

        function success(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.success(message, title, overrideOptions);
            }
            $log.info('Success: ' + message, data);
        }

        function warn(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.warning(message, title, overrideOptions);
            }
            $log.warn('Warning: ' + message, data);
        }

        function clear(){
            toastr.clear();
        }

        function remove(){
            toastr.remove();
        }

    }
}());

(function(){

    'use strict';

    angular
        .module('app.home',[
            
        ])
        
        .config([function(){



        }]);

})();




(function(){

    'use strict';

    angular
        .module('app.home')
        .controller('ToolsController',ToolsController);
        
    ToolsController.$inject = ['$scope','common'];


    //Tools Controller
	function ToolsController($scope,common){
	    $scope.common = common;
		$scope.calculateMonthly = calculateMonthly;
		
		//Variables
		$scope.mortgage = {loanamount:200000, rate:0.035, downpayment:40000, months:30,monthlybill:0,monthlytax:0};
		$scope.loans = [
             {value:10,description: '10 Year'}
            ,{value:15,description: '15 Year'}
            ,{value:20,description: '20 Year'}
            ,{value:25,description: '25 Year'}
            ,{value:30,description: '30 Year'}            
        ];

		//Watch Change on Mortgage Details
        $scope.$watch('mortgage', $scope.calculateMonthly,true);

		//Monthly Mortgage Calculator
        function calculateMonthly(){
            var loanamount = $scope.mortgage.loanamount || 0;
            var downpayment = $scope.mortgage.downpayment || 0;
            var rate = $scope.mortgage.rate || 0;
            var term = $scope.mortgage.months || 0;
            var monthlytax = $scope.mortgage.monthlytax || 0;
            var p = (loanamount - downpayment); //principle / initial amount borrowed
            var i = (rate*100) / 100 / 12; //monthly interest rate
            var n = term * 12; //number of payments months

            //console.log('MORTGAGE',$scope.mortgage);
            $scope.mortgage.monthlybill = ((p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)) + monthlytax) || 0;
        }

	};

})();


(function(){

    'use strict';

    angular
        .module('app.home')
        .controller('HomeController',HomeController);
        
    HomeController.$inject = ['$scope','common','listingsservice'];


    //Tools Controller
    function HomeController($scope,common,listingsservice){
        
        //METHODS
        $scope.init = init;
        $scope.getListings = getListings;
        $scope.setLoadingIndicator = setLoadingIndicator;
        $scope.getListingImages = getListingImages;
        
        //VARIABLES
        $scope.common = common;
        $scope.listings = [];
        $scope.showLoadingIndicator = 0;
        $scope.search = {dailylisting: 1};
        
        //Module Init Function
        $scope.init();
        function init(){
            $scope.getListings();
        }
        
        //Search for Listings
        function getListings(){
            var params = $scope.search;
            setLoadingIndicator(1);
            listingsservice.getListings(params).then(
                function(data){
                    //Set Listings
                    $scope.listings = data.listings;
                    setLoadingIndicator(0);
                }
            );
        }

        //Get Listing Images
        function getListingImages(matrixid){
            var params = {matrixuniqueid:matrixid};
            listingsservice.getListingImages(params).then(
                function(data){
                    console.log('images',data);
                    //Set Listing Object
                    $scope.listing.images = data;
                }
            );
        }

        //Set Loading Indicator
        function setLoadingIndicator(val){
            $scope.showLoadingIndicator = val;
        }    
    };

})();





(function(){

    'use strict';

    angular
        .module('app.home')
        .controller('AboutController',AboutController);
        
    AboutController.$inject = ['$scope','common','ngMeta'];


    //About Controller
	function AboutController($scope,common,ngMeta){
	    //METHODS
        $scope.common = common;
        $scope.init = init;
        $scope.setMetaInfo = setMetaInfo;

        //Module Init
        $scope.init();
        function init(){
            //$scope.setMetaInfo();
        }

        //Set Meta title and description
        function setMetaInfo(){
            ngMeta.setTitle('About Ely');
            ngMeta.setTag('description','White Pine County offeres scenic views from the great oudoors while providing residents with a strong feeling of community.');
        }

	};
	

})();




(function(){

    'use strict';

    angular
        .module('Utilities', [])
        .factory('utility', utilities)
        .factory('HttpService',HttpService)
        .directive('httpProgress',httpProgress);

        utilities.$inject = ['$log','$filter','HttpService'];

        function utilities($log,$filter,HttpService) {

            $log.log('Js Utilities has loaded.');

            var factory = {
                odbcToNGDate    : odbcToNGDate,
                HttpService     : HttpService
            };

            return factory;

           
            // convert sql odbc datetime to angular date
            function odbcToNGDate(odbcdate,format){
                var format = format || 'longDate'; // 'shortDate'
                var date = new Date(odbcdate);
                return $filter('date')(date,format);
            }

        }

        HttpService.$inject = ['$http','$q','$log','logger'];

        function HttpService($http,$q,$log,logger){
            var factory = {
                sendRequest: sendRequest,
                post: post
            };
            return factory;

            //Send HTTP Request
            function sendRequest(url,params,method){
                if(method == undefined)
                    method="GET";
                else
                    method="POST";
                

                var request = $http({
                     method: method,
                     url: _hostName + url,
                     data: params
                 });
                return(request.then(handleSuccess,handleError));
            }

            //Send HTTP Request
            function post(url,params,method){
                if(method == undefined)
                    method="GET";
                else
                    method="POST";
                

                var request = $http({
                     method: method,
                     url: _hostName + url,
                     params: params
                 });
                return(request.then(handleSuccess,handleError));
            }

            //Error Handler
            function handleError(response) {
                if (!angular.isObject( response.data ) || ! response.data.message){
                    logger.error("An unknown error occurred.");
                    return($q.reject("An unknown error occurred."));
                }
                logger.error(response.data.message, {}, 'Request Error', {timeOut: 10000,positionClass:'toast-bottom-right'});
                return($q.reject(response.data.message));
            }

            //Success Handler
            function handleSuccess(response){
                return response.data;
            }
        }

        function httpProgress(){
            return {
                restrict: 'E',
                controller: httpProgressController,
                templateUrl: '/assets/js/Utilities/http-progress.html'
            };
        }

        function httpProgressController($scope){
            
        }

})();

(function(){

    'use strict';

    //Create Module
    var app = angular.module('app',['app.core','app.listings','app.contact','app.home']);
        app.controller('appController',appController);
        
    appController.$inject = ['$scope','$log','common','$rootScope','logger'];

    app.run(function(ngMeta){
        ngMeta.init();
        console.log('ngmeta loaded');
    });

    function appController($scope,$log,common,$rootScope,logger){
        $scope.common = common;
        $scope.adminID = ((_AdminID == '')?undefined:_AdminID);
        $scope.currentYear = new Date();
        $scope.currentYear = $scope.currentYear.getFullYear();

        //Show Carousel if on Home Page
        $scope.showCarousel = function(){
            if($rootScope.pagetitle == 'Real Estate Professionals | Klaas Realty')
                return true;
            else
                return false;
        } 
       
    }
    
    //Header Custom Directive
    app.directive("dirnavigation",function(){
        return{
             restrict: 'E'
            ,templateUrl: '/src/app/_partials/navigation.html'

        };
    });

    //Photoswipe Custom Directive
    app.directive("photoswipe",function(){
        return{
             restrict: 'E'
            ,templateUrl: '/src/app/_partials/photoswipe.html'
        };
    });

})();

(function(){

    'use strict';

    angular
        .module('app.core',[
            // Angular Modules
            'ngRoute',
            'ngCookies',
            'ngAnimate',
            'vcRecaptcha',
            'ngMaterial',
            'uiGmapgoogle-maps',
            'ui.bootstrap',
            'ui.utils.masks',
            'angular.filter',
            'rzSlider',
            'Logger',
            'ngMeta',
            
            /*
            ** This host app requires 'ngMaterial'
            ** Since 'psomas.theme' (below) already includes
            ** 'ngMaterial' we do not need to include it again.
            */

            // Other 3rd-party
            //'Logger',

            // Utilities
            'Utilities'
        ])
})();


(function(){
    'use strict';

    angular
        .module('app.core')
        .config(['$routeProvider','$locationProvider','ngMetaProvider',function($routeProvider,$locationProvider,ngMetaProvider){

            $routeProvider
                .when('/', {
                     templateUrl: '/src/app/home/home.html'
                    ,controller: 'HomeController'
                    ,meta: {
                         title: 'Real Estate Professionals | Klaas Realty'
                        ,description: 'Offering professional real estate services in the White Pine County area. We are experts in residential, commercial, and land sales.'
                        ,url: 'http://www.klaasrealty.net/'
                    }
                })
                .when('/aboutely', {
                     templateUrl: '/src/app/home/about.html'
                    ,controller: 'AboutController'
                    ,meta: {
                         title: "Klaas Realty | What's great about White Pine County?"
                        ,description: "White Pine County offeres scenic views from the great oudoors while providing residents with a strong feeling of community."
                        ,url: "http://www.klaasrealty.net/aboutely"
                    }
                })
                .when('/tools', {
                     templateUrl: '/src/app/home/tools.html'
                    ,controller: 'ToolsController'
                    ,meta: {
                         title: 'Klaas Realty | What can I afford?'
                        ,description: 'Our affordibility calculators can help you plan for your next real estate purchase.'
                        ,url: 'http://www.klaasrealty.net/tools'
                    }
                })
                .when('/contact', {
                     templateUrl: '/src/app/contact/contact.html'
                    ,controller: 'ContactController'
                    ,meta: {
                         title: " Klaas Realty | Contact us today for your real estate needs."
                        ,description: "Use our contact form to send us an email or you can view other contact related information."
                        ,url: "http://www.klaasrealty.net/contact"
                    }
                })
                .when('/listings', {
                     templateUrl: '/src/app/listings/listings.html'
                    ,controller: 'ListingsController'
                    ,meta: {
                         title: "Klaas Realty | Browse White Pine Couty listings."
                        ,description: "Klaas Realty has new listings for sale! View listing details, photos, and virtual tours."
                        ,url: "http://www.klaasrealty.net/listings"
                    }
                })
                .when('/listing/:listingkey', {
                     templateUrl: '/src/app/listings/listingdetail.html'
                    ,controller: 'ListingDetailController'
                    ,meta: {
                         title: "" //Loaded from controller
                        ,description: "" //Loaded from controller
                        ,url: "" //Loaded from controller
                    }
                })
                .otherwise({redirectTo:'/'});

                $locationProvider.html5Mode(true);
                //$locationProvider.hashPrefix('!');
        }])

        //Set Page Title based on route value
        angular.module('app').run(['$location', '$rootScope', function($location, $rootScope) {
            $rootScope.$on('$routeChangeSuccess', function (event, current, previous){
                 if (current.hasOwnProperty('$$route')){
                     $rootScope.pagetitle = current.$$route.meta.title;
                 }
                 document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
        }]);
})();


(function() {
    'use strict';

    angular
        .module('app.core')
        .factory('listingsservice',listingsservice)
        .factory('mailservice',mailservice);

  
    //********************************************************
    //LISTINGS Service
    //********************************************************
    listingsservice.$inject = ['utility'];
    mailservice.$inject = ['utility'];

    var apipath = '/rest/realtyapi';

    function listingsservice(utility) {
        var service = {
             getListings: getListings
            ,getSingleListing: getSingleListing
            ,getListingImages: getListingImages
            ,getListingPropertyTypes: getListingPropertyTypes
            ,getBedrooms: getBedrooms
            ,getBathrooms: getBathrooms
            ,getPriceRange: getPriceRange           
        };
        return service;

        //Get Listings
        function getListings(params){
            return utility.HttpService.sendRequest(apipath + '/listings/get/listings/',params,'post');
        }   

        //Get Single Listing
        function getSingleListing(params){
            return utility.HttpService.sendRequest(apipath + '/listings/get/listings/single',params,'post');
        }   

        //Get Listing Images
        function getListingImages(params){
            return utility.HttpService.sendRequest(apipath + '/listings/get/listing/images/',params,'post');
        }   

        //Get List of Property Types
        function getListingPropertyTypes(){
            return utility.HttpService.sendRequest(apipath + '/listings/get/listing/propertytypes');
        }   
       
        //Get Max Bedrooms
        function getBedrooms(){
            return utility.HttpService.sendRequest(apipath + '/listings/get/range/bedrooms');
        }   

        //Get Max Bathrooms
        function getBathrooms(){
            return utility.HttpService.sendRequest(apipath + '/listings/get/range/bathrooms');
        }  

        //Get Price Range
        function getPriceRange(){
            return utility.HttpService.sendRequest(apipath + '/listings/get/range/price');
        }

    }

    function mailservice(utility) {
        var service = {
             sendContactUs: sendContactUs
        };
        return service;

        //Contact Us
        function sendContactUs(params){
            return utility.HttpService.sendRequest(apipath + '/mail/contactus',params,'post');
        }   
    }

   

})();
(function(){

    'use strict';

    var core = angular.module('app.core');

    var config = {
        appTitle: 'Klaas Realty',
        version: '2.0'
    };

    core.value('config', config);

    core.config(function($mdThemingProvider){
		$mdThemingProvider.definePalette('KlaasRealtyPalette', {
			'50': '#FFFFFF',
		    '100': '#C5CAE9',
		    '200': '#254eb6',
		    '300': '#2145a0',
		    '400': '#1c3c8b',
		    '500': '#183376',
		    '600': '#142a61',
		    '700': '#0f214c',
		    '800': '#0b1836',
		    '900': '#070e21',
		    'A100': '#4c75db',
		    'A200': '#6286df',
		    'A400': '#7796e3',
		    'A700': '#02050c',
		    'contrastDefaultColor': 'light'
		});

	$mdThemingProvider.theme('default').primaryPalette('KlaasRealtyPalette')
	});

    // TODO: look up how to use .value .constant .config etc.

})();

(function(){

    'use strict';

    angular
        .module('app.core')
        .factory('common', common);
        
    common.$inject = ['$log','$http','$location','$q','$routeParams','$filter','logger'];    

    function common($log,$http,$location,$q,$routeParams,$filter,logger){
        $log.log('App.Core Common has loaded.');

        var factory = {
            navigateToLink: navigateToLink,
            routeTo: routeTo,
            buildstring: buildstring,
            buildaddress: buildaddress,
            arrayLength: arrayLength,
            listToArray: listToArray,
            isStringDefined: isStringDefined,
            isNotDefined: isNotDefined,
            buildMapSource: buildMapSource,
            openPhotoSwipe: openPhotoSwipe,
            logger              : logger,
            $log                : $log,
            $http               : $http,
            $q                  : $q,
            $location           : $location,
            $routeParams        : $routeParams
        }
        return factory;

        //Navigate to Link
        function navigateToLink(link){
            document.location.href = link;
        };      

        //Navigate to Route
        function routeTo(path,params){
            if(params !== undefined){
                $location.path(path).search(params);
            }else{
                $location.path(path);
            }
        };  

        //Builds Property Details String
        function buildstring(island,bedrooms,bathrooms,sqft,lotsize){
            var arrString = [];
            var sqft1 = $filter('number')(sqft,0);
            var lotsize1 = $filter('number')(lotsize,0);
            
            if(bedrooms > 0)
                arrString.push(bedrooms+' Bedrooms')
            
            if(bathrooms > 0)
                arrString.push(bathrooms+' Bathrooms')

            if(island==0 && sqft > 0)
                arrString.push(sqft1+' sq ft')

            if(island==1 && lotsize > 0)
                arrString.push(lotsize1+' sq ft');

            return arrString.join(', ');
        }

        //Check to see if listing has images
        function arrayLength(myarray){
            if(myarray.length > 0){
                return true;
            }else{
                return false;
            }
        };

        //Build Map Embed Source
        function buildMapSource(latitude,longitude,key){
            var mapString = 'https://www.google.com/maps/embed/v1/place?key=';
            mapString = mapString + key;
            mapString = mapString + '&q=';
            mapString = mapString + latitude + ',' + longitude;
            return mapString;
        }

        //Function to Check undefined
        function isStringDefined(mystring){
            if(mystring === undefined || mystring.length < 1){
                return false;
            }else{
                return true;
            }
        }

        function isNotDefined(mystring){
            if(mystring === undefined || mystring.length < 1){
                return true;
            }else{
                return false;
            }
        }

        //List TO Array Function
        function listToArray(myList){
            var myArray = [];
            myArray = myList.split(',');
            return myArray;
        }

        //Build Address String
        function buildaddress(address,city,state,zipcode){
            var arrString = [];

            if(address !== undefined)
                arrString.push(address);

            if(city !== undefined)
                arrString.push(city);

            if(state !== undefined)
                arrString.push(state);

            if(zipcode !== undefined && zipcode != '')
                arrString.push(zipcode);

            return arrString.join(', ');
        }

        //Open PhotoSwipe
        function openPhotoSwipe(images){
            var pswpElement = document.querySelectorAll('.pswp')[0];
            var items = [];

            //Options to Eclude history | Messes with AngularRoute if enabled
            var options = {
                history: false,
                backButtonHideEnabled: false
            };

            //Populate Photo Slider
            angular.forEach(images,function(item){
                var obj = {};
                obj.src = item.IMAGEPATH;
                obj.w = 800;
                obj.h = 600;
                this.push(obj)
            },items);
            
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items,options);
            gallery.init();
        };

    };

})();

(function(){

    'use strict';

    angular
        .module('app.listings',[
            
        ])
        
        .config([function(){



        }]);

})();



(function(){

    'use strict';

    angular
        .module('app.listings')
        .controller('ListingsController',ListingsController);
        
    ListingsController.$inject = ['$http','$scope','common','$filter','$mdDialog','$mdMedia','listingsservice','$timeout'];

    //Listings Controller
    function ListingsController($http,$scope,common,$filter,$mdDialog,$mdMedia,listingsservice,$timeout){
        console.log('Listings controller has been called');
        
        //METHODS
        $scope.common = common;
        $scope.init = init;
        $scope.getListings = getListings;
        $scope.filterListings = filterListings;
        $scope.resetSearch = resetSearch;       
        $scope.getFilterItems = getFilterItems;
        $scope.viewdetails = viewdetails;
        $scope.hasPrice = hasPrice;
        $scope.hasVideo = hasVideo;
        $scope.setLoadingIndicator = setLoadingIndicator;

        //VARIABLES
        $scope.listings = [];
        $scope.showLoadingIndicator = 0;
        $scope.listing = {};        
        $scope.search = {propertytypecombinedcode:'',bedstotal:'',bathstotal:'',minprice:0,maxprice: 10000000};

        //Search Default Model
        $scope.searchdefaults = {
             propertytypecombinedcode: []
            ,bedrooms: []
            ,bathrooms: []
            ,slider: {
                 min: 0
                ,max: 1000
                ,options: {
                     floor: 0
                    ,ceil: 100000
                    ,step: 25000
                    ,hideLimitLabels:true
                    ,translate: function(value) {
                      return '$' + value;
                    }
                }
            }
        };
              
        //Init Function
        $scope.init();
        function init(){
            getListings();
            getFilterItems();        
        }
        
        //Renders Price Slider
        function refreshSlider(){
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        //Search for Listings
        function getListings(showtoastr){
            var params = $scope.search;
            console.log(params);
            setLoadingIndicator(1);
            listingsservice.getListings(params).then(
                function(data){

                    //console.log(data.listings,'ASD');
                    //Set Listings
                    $scope.listings = data.listings;

                    //Hide Loading Bar
                    setLoadingIndicator(0);

                    //Search Message
                    common.logger.success(data.listings.length+' listings found matching your search criteria.','','Search Complete');
                }
            );
        }

           //Get Listing Images
        function getListingImages(matrixid){
            var params = {matrixuniqueid:matrixid};
            listingsservice.getListingImages(params).then(
                function(data){
                    console.log('images',data);
                    //Set Listing Object
                    $scope.listing.images = data;
                }
            );
        }

        //Search For Listings
        function filterListings(){
            getListings(1);
        }

        //Reset Search Form and Get Lsitings
        function resetSearch(){
            $scope.search = {propertytypecombinedcode:'',bedstotal:'',bathstotal:'',minprice: $scope.searchdefaults.minprice,maxprice: $scope.searchdefaults.maxprice}
            getListings();
        }

        //Get Filter Values
        function getFilterItems(){
            //Get Use Codes
            listingsservice.getListingPropertyTypes().then(
                function(results){
                    $scope.searchdefaults.propertytypecombinedcode = results;
                    console.log('HERE',results);
                }
            );

            //Get Use Codes
            listingsservice.getBedrooms().then(
                function(results){
                    $scope.searchdefaults.bedrooms = results;
                }
            );

            //Get Use Codes
            listingsservice.getBathrooms().then(
                function(results){
                    $scope.searchdefaults.bathrooms = results;
                }
            );

            //Get Price Range
            listingsservice.getPriceRange().then(
                function(results){
                    $scope.searchdefaults.slider.min = 0;
                    $scope.searchdefaults.slider.options.floor = 0;
                    $scope.search.minprice = 0;
                    $scope.searchdefaults.slider.max = results[0].MAXPRICE;
                    $scope.searchdefaults.slider.options.ceil = results[0].MAXPRICE;
                    $scope.search.maxprice = results[0].MAXPRICE;
                     refreshSlider();
                }
            );
            //console.log($scope.searchdefaults);
        }

        //View Video
        function viewdetails(ev,listing) {
            var useFullScreen = ($mdMedia('sm') || $mdMedia('xs'))  && $scope.customFullscreen;
            $scope.listing = {};
            $scope.listing = listing;

            $mdDialog.show({
              controller: DialogController,
              controllerAs: 'ctrl',
              templateUrl: '/app/_partials/video.html',
              targetEvent: ev,
              locals:{
                listing: listing
              },
              clickOutsideToClose:true
            })        
        };

        //Check to see if listing has images
        function hasPrice(price){
            if(price > 0){
                return true;
            }else{
                return false;
            }
        };

        //Check to see if listing has video
        function hasVideo(url){
            if(url.length > 0){
                return false;
            }else{
                return true;
            }
        };

        //Set Loading Indicator
        function setLoadingIndicator(val){
            $scope.showLoadingIndicator = val;
        }      

    };

})();





(function(){

    'use strict';

    angular
        .module('app.listings')
        .controller('ListingDetailController',ListingDetailController)
        .filter('percentage', ['$filter', function ($filter) {
            return function (input, decimals) {
            return $filter('number')(input * 100, 2) + ' %';
        };
        }]);
    ListingDetailController.$inject = ['$scope','common','$filter','listingsservice','$mdDialog','$mdMedia','$rootScope','ngMeta'];
    

    


    //Listings Detail Controller
    function ListingDetailController($scope,common,$filter,listingsservice,$mdDialog,$mdMedia,$rootScope,ngMeta){
        
        //METHODS
        $scope.common = common;
        $scope.init = init;
        $scope.getListing = getListing;
        $scope.setListing = setListing;
        $scope.getListingImages = getListingImages;
        $scope.calculateMonthly = calculateMonthly;

        //Variables
        $scope.listing = {};
        $scope.streetaddressconcat = common.$routeParams.listingkey;
        $scope.googleMapsKey = 'AIzaSyBNI2Q5s1ffsmbjyR7m5Zx5QWtHrHpA3vM';
        //$scope.googleMapURL = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBNI2Q5s1ffsmbjyR7m5Zx5QWtHrHpA3vM&q=Space+Needle,Seattle+WA';
        $scope.map = {};
        $scope.marker = {};
        $scope.mortgageestimatedisclaimer = "Estimate based on 20% down, 30 year term, 3.5% interest, and good credit.";
        $scope.originalestimate = 0;
        $scope.mortgage = {loanamount:100000, rate:0.035, downpayment:0, months:30,monthlybill:0,monthlytax:0};

        $scope.loans = [
             {value:10,description: '10 Year'}
            ,{value:15,description: '15 Year'}
            ,{value:20,description: '20 Year'}
            ,{value:25,description: '25 Year'}
            ,{value:30,description: '30 Year'}            
        ];

        //Watch Change on Mortgage Details
        $scope.$watch('mortgage', $scope.calculateMonthly,true);

        //Get Listings
        $scope.init();
        function init(){
            $scope.getListing();
        } 

        //Search for Listings
        function getListing(){            
            //Get Listing by StreetAddressConcat
            var params = {streetaddressconcat: $scope.streetaddressconcat};
            listingsservice.getSingleListing(params).then(
                function(listing){
                    //console.log("listings",listing[0]);

                    //Set Listing Object
                    $scope.listing = setListing(listing[0]);

                    //Set Loan Amount
                    $scope.mortgage.loanamount = $scope.listing.currentprice;
                    $scope.mortgage.downpayment = $scope.listing.currentprice * 0.20;
                    $scope.mortgage.monthlytax = $scope.listing.monthlytax;
                    $scope.calculateMonthly();

                    $scope.originalestimate = angular.copy($scope.mortgage.monthlybill);

                    //NG-META
                    var addressstring = $scope.listing.streetaddress + ' ' + $scope.listing.city + ', ' + $scope.listing.stateorprovince;
                    var newtitle = "Klaas Realty | " + addressstring;
                    ngMeta.setTitle(newtitle);
                    ngMeta.setTag('description','View listing details, photos, and video walk throughs for '+addressstring);
                    ngMeta.setTag('url','http://www.klaasrealty.net/listing/'+$scope.listing.STREETADDRESSCONCAT);     

                    //$scope.googleMapURL = common.buildMapSource($scope.listing.latitude,$scope.listing.longitude,$scope.googleMapsKey);         
                    //Map Attributes
                    //setMapOptions($scope.listing.latitude,$scope.listing.longitude);               
                }
            );
        }

        //Get Listing Images
        function getListingImages(matrixid){
            var params = {matrixuniqueid:matrixid};
            listingsservice.getListingImages(params).then(
                function(data){
                    //Set Listing Object
                    $scope.listing.images = data;
                }
            );
        }

        //Set Listing Object
        function setListing(listing){
            var newlisting = {};
            newlisting.bathstotal=listing.BATHSTOTAL;
            newlisting.bedstotal=listing.BEDSTOTAL;
            newlisting.city=listing.CITY;
            newlisting.countyorparish=listing.COUNTYORPARISH;
            newlisting.currentprice=listing.CURRENTPRICE;
            newlisting.dailylisting=listing.DAILYLISTING;
            newlisting.doe=listing.DOE;
            newlisting.garage=listing.GARAGE;
            newlisting.garagedescription=listing.GARAGEDESCRIPTION;
            newlisting.id=listing.ID;
            newlisting.island=listing.ISLAND;
            newlisting.listagentfullname=listing.LISTAGENTFULLNAME;
            newlisting.listofficename = listing.LISTOFFICENAME;
            newlisting.listprice=listing.LISTPRICE;
            newlisting.lotsqft=listing.LOTSQFT;
            newlisting.matrixuniqueid=listing.MATRIXUNIQUEID;
            newlisting.mls=listing.MLS;
            newlisting.mlsnumber=listing.MLSNUMBER;
            newlisting.postalcode=listing.POSTALCODE;
            newlisting.propertysubtypecode=listing.PROPERTYSUBTYPECODE;
            newlisting.propertysubtypeicon=listing.PROPERTYSUBTYPEICON;
            newlisting.propertysubtypelabel=listing.PROPERTYSUBTYPELABEL;
            newlisting.propertytypecode=listing.PROPERTYTYPECODE;
            newlisting.propertytypeicon=listing.PROPERTYTYPEICON;
            newlisting.propertytypelabel=listing.PROPERTYTYPELABEL;
            newlisting.publicremarks=listing.PUBLICREMARKS;
            newlisting.sqfttotal=listing.SQFTTOTAL;
            newlisting.stateorprovince=listing.STATEORPROVINCE;
            newlisting.status=listing.STATUS;
            newlisting.streetaddress=listing.STREETADDRESS;
            newlisting.streetaddressconcat=listing.STREETADDRESSCONCAT;
            newlisting.streetname=listing.STREETNAME;
            newlisting.streetnumber=listing.STREETNUMBER;
            newlisting.yearbuilt=listing.YEARBUILT;
            newlisting.mainimage=listing.MAINIMAGE;
            newlisting.annualtax=listing.ANNUALTAX;
            newlisting.monthlytax=listing.MONTHLYTAX;            
            newlisting.images = listing.IMAGES;
            newlisting.garageattributes = listing.GARAGEATTRIBUTES;
            newlisting.roomcount = listing.ROOMCOUNT;
            newlisting.housefaces = listing.HOUSEFACES;
            newlisting.houseviewsattributes = listing.HOUSEVIEWSATTRIBUTES;
            newlisting.energyattributes = listing.ENERGYATTRIBUTES;
            newlisting.virtualtour = listing.VIRTUALTOUR;
            newlisting.heatingattributes = listing.HEATINGATTRIBUTES;
            newlisting.parcelnumber = listing.PARCELNUMBER;
            newlisting.numparcels = listing.NUMPARCELS;
            newlisting.zoningattributes = listing.ZONINGATTRIBUTES;
            newlisting.lotattributes = listing.LOTATTRIBUTES;

            //newlisting.videourl = data[0].VIDEOURL;
            return newlisting;
        }

        //Initialize Map
        function initMap(){
           var map = new google.maps.Map(document.getElementById('map'), {
              center: {lat: -34.397, lng: 150.644},
              zoom: 8
            });
        }

        //Monthly Mortgage Calculator
        function calculateMonthly(){
            var loanamount = $scope.mortgage.loanamount || 0;
            var downpayment = $scope.mortgage.downpayment || 0;
            var rate = $scope.mortgage.rate || 0;
            var term = $scope.mortgage.months || 0;
            var monthlytax = $scope.mortgage.monthlytax || 0;
            var p = (loanamount - downpayment); //principle / initial amount borrowed
            var i = (rate*100) / 100 / 12; //monthly interest rate
            var n = term * 12; //number of payments months

            //console.log('MORTGAGE',$scope.mortgage);
            $scope.mortgage.monthlybill = ((p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)) + monthlytax) || 0;
        }

        //Set Map Options
        function setMapOptions(lat,lon){
            //THIS PREVENTS GOOGLE MAPS FROM OVERRIDING WEBSITE FONT
            var head = document.getElementsByTagName('head')[0];
            // Save the original method
            var insertBefore = head.insertBefore;

            // Replace it!
            head.insertBefore = function (newElement, referenceElement) {
                if(newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0){
                    //console.info('Prevented Roboto from loading!');
                    return;
                }
                insertBefore.call(head, newElement, referenceElement);
            };
            //END: THIS PREVENTS GOOGLE MAPS FROM OVERRIDING WEBSITE FONT


            $scope.map = {center: { latitude: lat, longitude: lon }, zoom: 17, options: {scrollwheel: false}};
            $scope.marker = {id: 0, coords: {latitude: lat,longitude: lon}};
            $scope.marker.options = {
                    draggable: true,
                    labelContent: "lat: " + lat + ' ' + 'lon: ' + lon,
                    labelAnchor: "100 0",
                    labelClass: "marker-labels"
                  }
        }

   };

})();

$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip(); 
});
(function(){

    'use strict';

    angular
        .module('app.contact',[
            
        ])
        
        .config([function(){



        }]);

})();



(function(){

    'use strict';

    angular
        .module('app.contact')
        .controller('ContactController',ContactController);
        
    ContactController.$inject = ['$scope','$http','vcRecaptchaService','$location','$anchorScroll','common','ngMeta','mailservice'];


    //About Controller
    function ContactController($scope,$http,vcRecaptchaService,$location,$anchorScroll,common,ngMeta,mailservice){
        //METHODS
        $scope.common = common;
        $scope.init = init;
        $scope.setResponse = setResponse;
        $scope.setWidgetId = setWidgetId;
        $scope.getMailStatus = getMailStatus;
        
        $scope.sendContactUs = sendContactUs;
        $scope.getKey = getKey;
        $scope.setMetaInfo = setMetaInfo;

        //VARIABLES
        //$scope.mailModel = {name: 'Robert', phone: '714.882.9214', email: 'robertklaas@gmail.com', message: 'body',grecaptcharesponse: ''};
        $scope.mailModel = {name: '', phone: '', email: '', message: '',grecaptcharesponse: ''};
        $scope.response = null;
        $scope.widgetId = null;
        $scope.model = {key: '6Lf_zAITAAAAACVUfZctex02vyVj4XmqEFbO9Lgz'};
        $scope.mailStatus = 0;

        //Module Init
        $scope.init();
        function init(){
            //$scope.setMetaInfo();
        }

        //Set Meta title and description
        function setMetaInfo(){
            ngMeta.setTitle('Contact Us');
            ngMeta.setTag('description','Use our contact form to send us an email or you can view other contact related information.');
        }

        //Set Recaptcha Response to Model
        function setResponse(response){
            //console.log('Response Returned');
            $scope.mailModel.grecaptcharesponse = response;
        };

        //Send Contact Us Mail
        function sendContactUs(){
            var params = $scope.mailModel;
            mailservice.sendContactUs(params).then(
                function(data){
                    //Clear out NoCaptcha and Empty Form
                    vcRecaptchaService.reload($scope.widgetId); //CLEAR OUT RECAPTCHA
                    $scope.mailStatus = parseInt(data);
                    //console.log('ms' + $scope.mailStatus);
                }
            );
        }

        //Return Recaptcha Key
        function getKey(){
            return $scope.model.key;
        }

        //Set Widgit ID in scope
        function setWidgetId(widgetId){
            //console.info('Created widget ID: %s', widgetId);
            $scope.widgetId = widgetId;
        };

        //Return Mail Status
        function getMailStatus(statusid){
            if(statusid === $scope.mailStatus){
                return true
            }else{
                return false
            }
        }
    };
})();


